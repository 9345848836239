import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../css/juster.css"
import "../css/animation.css"
import Schedule from "../images/schedule.svg"
import Navigate from "../images/navigate.svg"
import Record from "../images/record.svg"
import Photocap from "../images/photo_c.svg"
import Checklist from "../images/checklist_g.svg"
import ScopingReport from "../images/scoping_report.svg"
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet"

export default () => (
  <>
    <BodyClassName className="blob_bg" />
    <Helmet>
      <title>JustEZ - Manage the Field Inspection Assignments</title>          
      <meta name="description" content="Sync & schedule inspection assignments, navigate to loss site, record inspection details, take photos/videos and get the scoping reports swiftly and accurately. Sign up today for free!" />
      <meta name="keywords" content="insurance adjusters,adjusters,independent insurance adjusters,field inspection,inspection reports,site inspection,inspection,sync inspections,schedule inspections" />"
    </Helmet>
    <Header></Header>

    <div class="tp-integrations how_it_works_b tp-tutorial-banner">
      <div class="container tp-integrations-container">
        <div class="integrations-info">
          <h2>How it Works</h2>
          <p>
            Insurance Adjusters serve one great purpose for carriers beyond loss
            assessment. That is to uphold the carrier's customer service
            quality.
            <br></br>
            Yes, you read it right. We're extension to carrier's customer
            support team. Being an adjuster you need to be efficient and focused
            during site inspection.
            <br></br>
            Also, it is important to have all the information at hand and
            knowledge about the claim.
          </p>
        </div>
      </div>
    </div>

    <div class="container-main">
      <div class="container how_it_works_pg">
        <div class="quote_ctr">
          <span class="quote_mark">&#8220;</span>
          <span class="quote_txt">
            Our team worked with some of the efficient insurance adjusters and
            came up with a simple and intuitive process.
          </span>
          <span class="quote_mark">&#8221;</span>
        </div>

        <div class="how_it_wrks_block">
          <div class="hiw_logo_ctr">
            <img alt="Schedule" src={Schedule} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Schedule</div>
            <p>
              Everything starts in JustEZ from Scheduling or we call it
              Intelligent Scheduling. We always miss priorities when assignments
              come from different carriers. And, when we focus on priorities we
              fail to achieve productivity. It is important to maintain balance
              between priority and productivity.
            </p>
            <p>
              JustEZ handles this situation with proactive scheduling instead of
              reactive scheduling. It understands your work style and suggests
              every assignments with priorities. It knows about your daily
              roaster and can tell how far away each inspection is. Also, it
              understands driving time, navigational map information, the time
              you generally take to assess a property and more importantly, your
              personal appointments. Therefore, it helps in planning inspection
              schedules.
            </p>
          </div>
        </div>

        <div class="how_it_wrks_block alt">
          <div class="hiw_logo_ctr">
            <img alt="Navigate" src={Navigate} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Navigate</div>
            <p>
              After Scheduling, the next big thing is to reach out to the loss
              site. JustEZ integrates with Waze and Google maps to provide
              navigation capabilities. When you drive using any of these maps,
              you get real-time traffic updates, and so much more.
            </p>
            <p>
              We all know how much painful it is to type in claimant’s address
              every time you start driving to the loss location. But with
              JustEZ, it is so convenient as it picks the address automatically
              from claim information.
            </p>
          </div>
        </div>

        <div class="how_it_wrks_block">
          <div class="hiw_logo_ctr">
            <img alt="Record" src={Record} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Record</div>
            <p>
              Someone rightly said, “A picture is worth a thousand words”.
              Photos have the most valuable role in inspection reports. They
              should be clear and explicable and more photos are generally
              better than less. Insurance adjusters always look for photo
              documentation which is descriptive and useful for communicating
              the unbiased facts. But working on this type of documentation
              consumes their plenty of time. Also, we know that adjusters hate
              to use their phones for taking photos at the loss site.
            </p>
            <p>
              JustEZ makes it super easy to prepare photo documentation. It can
              connect to your point-and-shoot camera and download photos. Or,
              you can use your smartphone’s camera to take photos. Now, no more
              transferring photos by pulling SD card out of camera and
              connecting to local devices. As all this happens automatically in
              JustEZ.
            </p>
          </div>
        </div>

        <div class="how_it_wrks_block alt">
          <div class="hiw_logo_ctr">
            <img alt="Photo Captioning" src={Photocap} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Photo Captioning</div>
            <p>
              Isn't it difficult to caption hundred photos taken during the site
              inspection? And, it becomes more difficult when you complete 3-4
              inspections in a day and caption all photos at the end.
            </p>
            <p>
              At JustEZ, we use AI based photo captioning technology to caption
              photos We fondly call it "Smart Titling". With this smart
              captioning technology, it takes only few minutes to caption 100+
              photos using your tablet or smartphone. Hence, you save lot of
              time to do more inspections.
            </p>
          </div>
        </div>

        <div class="how_it_wrks_block">
          <div class="hiw_logo_ctr">
            <img alt="Checklist" src={Checklist} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Checklist</div>
            <p>
              It is the human tendency that we all tend to forget things. There
              are a set of questions mandatory by insurance companies to ask
              from the insured. Insurance adjusters use paper and pen to take
              notes of all these questions. And sometimes they do forget to ask
              any specific question from the insured.
            </p>
            <p>
              But that’s not at all a problem with JustEZ as it has predefined
              checklist that you can answer during the field inspection. Now,
              there is no need for hand taken notes or double entry work. All
              answers added in checklist become the part of final scoping report
              automatically.
            </p>
          </div>
        </div>

        <div class="how_it_wrks_block alt last">
          <div class="hiw_logo_ctr">
            <img alt="Scoping Report" src={ScopingReport} />
          </div>
          <div class="hiw_desc_ctr">
            <div class="heading">Scoping Report</div>
            <p>
              A clear and well documented report is what we all work for at the
              end of field inspection process. A report that includes specific
              description to damages, proper measurements, and notes that can be
              easily interpreted. We observed that it takes around 4 - 5 days of
              insurance adjusters to come up with such scoping report. And then
              they use Xactimate or Symbility to produce estimates.
            </p>
            <p>
              Whereas JustEZ is great tool for building final scoping reports.
              The scoping report gets generated itself when you complete the
              inspection. You can publish these reports or share with examinar
              while you drive to the next inspection.
            </p>
          </div>
        </div>

        <div class="clr"></div>
      </div>
    </div>

    <Footer></Footer>
  </>
)
